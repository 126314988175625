<template>
  <Modal
    click-to-close
    :z-index="10"
    close-button-text="Закрыть"
    hide-footer
    classes="modal fixed-bottom-modal bottom-0"
    class="mm-add-recipent-modal"
    @close="$emit('close')"
  >
    <div class="mm-add-recipent">
      <p>
        Имя и контактные данные получателя необходимы для доставки <br>
        товаров. Указывайте только реальную информацию.
      </p>

      <SearchDeputy
        v-model="searchText"
        placeholder="ФИО"
        :is-custom-indicator="true"
        :hide-indicator="true"
        :hide-empty-indicator="true"
        :is-clearable="true"
        :validation-field="form.name"
        :is-hide-no-results-label="false"
        :method-api="onSearchRecipent"
        :max-length="255"
        hide-description
        no-results-label="Сотрудник с введенными вами данными не найден"
        @selected-option="onSelectOption"
        @deselected-option="selectedUser = undefined"
      />

      <div class="mm-add-recipent--work-phone">
        <TextField
          v-model="form.workPhone.value.value"
          label="Номер телефона рабочий"
          icon-disabled
          :validation-field="form.workPhone"
          :mask="EInputMasks.PhoneSpaces"
          :disabled="disabledWorkPhone"
          input-spec-id="client_work_phone"
          @keydown="TelephoneUtil.onKeydownPhone(
            form.workPhone.value.value,
            $event,
            (val) => form.workPhone.value.value = val,
          )"
        />

        <TextField
          v-model="form.additionalCode.value.value"
          label="Добавочный"
          icon-disabled
          :max-length="7"
          :mask="INPUT_MASK.ONLY_NUMBERS"
          :validation-field="form.additionalCode"
          input-spec-id="client_extension_code"
        />
      </div>

      <TextField
        v-model="form.mobilePhone.value.value"
        label="Номер телефона мобильный"
        icon-disabled
        :validation-field="form.mobilePhone"
        :mask="EInputMasks.PhoneSpaces"
        input-spec-id="client_mobile_phone"
        @keydown="TelephoneUtil.onKeydownPhone(
          form.mobilePhone.value.value,
          $event,
          (val) => form.mobilePhone.value.value = val,
        )"
      />

      <button
        id="continue_button"
        class="btn btn-primary"
        :class="{ 'btn-disabled': !meta.valid }"
        :disabled="isBtnDisabled"
        @click="onAdd"
      >
        Продолжить
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from 'shared/components/modals/Modal.vue';
import TextField from 'shared/components/TextField.vue';
import { useUserStore } from 'store/user.store';
import { EInputMasks } from 'shared/enums/inputMasks.enum';
import { Validators } from 'shared/utils/validators.util';
import { configure, useField, useForm } from 'vee-validate';
import { BaseBasket } from 'services/basket-services/base-basket/baseBasket.service';
import { getValueByMask } from 'shared/utils/maskHelper.util';
import { INPUT_MASK } from 'shared/constants/inputMask.const';
import { ISelectOption, ISelectOptionDeputy } from 'shared/models/select.model';
import { ClientApi } from 'services/api/clientApi.service';
import SearchDeputy from 'components/client/SearchDeputy.vue';
import { IUserInfoResponse } from 'models/auth/userInfo.model';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import { TelephoneUtil } from 'shared/utils/telephone.util';

interface IForm {
  name: string;
  mobilePhone: string;
  workPhone: string;
  additionalCode: string;
}

enum EFormFields {
  Name = 'name',
  MobilePhone = 'mobilePhone',
  WorkPhone = 'workPhone',
  AdditionalCode = 'additionalCode',
}

const props = defineProps<{
  basketService: BaseBasket;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'ok'): void;
}>();

configure({
  validateOnChange: true,
  validateOnInput: true,
});

const validationSchema = {
  [EFormFields.Name]: [Validators.required(), Validators.maxLength(255)],
  [EFormFields.MobilePhone]: [(value?: string) => value ? Validators.phoneNumber().call(this, value) : true],
  [EFormFields.WorkPhone]: [Validators.required(), Validators.phoneNumber()],
  [EFormFields.AdditionalCode]: [],
};

const watchSubscription = new WatchSubscription();
const userStore = useUserStore();
const isBtnDisabled = ref(false);
const selectedUser = ref<ISelectOption>();
const searchText = ref<string>();
const disabledWorkPhone = computed<boolean>(() => Boolean(userStore?.userInfo?.beWorkPhone));

const { meta } = useForm<IForm>({
  validationSchema,
});

const form = reactive({
  name: useField<string>(EFormFields.Name, undefined, {
    initialValue:
      [userStore.userInfo?.lastName, userStore.userInfo?.name, userStore.userInfo?.patronymic].filter((value) => !!value).join(' ') ?? '',
  }),
  mobilePhone: useField<string>(EFormFields.MobilePhone, undefined, {
    initialValue: getValueByMask(userStore.userInfo?.phone, { mask: EInputMasks.PhoneSpaces }) ?? '',
  }),
  workPhone: useField<string>(EFormFields.WorkPhone, undefined, {
    initialValue: getValueByMask(userStore.userInfo?.beWorkPhone || '', { mask: EInputMasks.PhoneSpaces }) ?? '',
  }),
  additionalCode: useField<string>(EFormFields.AdditionalCode, undefined, {
    initialValue: (userStore.userInfo?.additionalCode || '').toString(),
  }),
});

async function onSearchRecipent(q: string): Promise<Array<IUserInfoResponse>> {
  return await ClientApi.searchClientRecipentWithBeId(q, props.basketService?.beId?.value);
}

async function onAdd(): Promise<void> {
  try {
    isBtnDisabled.value = true;
    props.basketService?.setBasketRecipient({
      name: form.name.value.value,
      mobilePhone: form.mobilePhone.value.value || null,
      workPhone: form.workPhone.value.value,
      additionalCode: form.additionalCode.value.value,
      sub: selectedUser.value?.sub as string,
    });

    emits('ok');
  } catch (err) {
    console.error(err);
  } finally {
    isBtnDisabled.value = false;
  }
}

function initSearchUserValue(): void {
  selectedUser.value = {
    sub: userStore.userInfo?.sub,
    label: form.name.value.value,
    value: form.name.value.value,
  };
  searchText.value = form.name.value.value;
}

function onSelectOption(user: ISelectOptionDeputy): void {
  selectedUser.value = user;
  form.mobilePhone.value.value = user.phone || '';
  form.additionalCode.value.value = user.additionalCode || '';
}

watchSubscription.add(
  watch(
    () => searchText.value,
    (value) => {
      if (value !== selectedUser.value?.value) {
        searchText.value = '';
        selectedUser.value = undefined;
      }

      form.name.value.value = selectedUser.value?.label;
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  initSearchUserValue();
});
</script>

<style lang="scss" scoped>
@import 'shared/assets/styles/base/common/variables';

.mm-add-recipent-modal {
  :deep(.modal) {
    .modal-header {
      align-items: flex-start;
      margin-bottom: 32px !important;
    }
  }

  :deep(.btn) {
    padding: 8px 16px !important;
  }

  :deep(.modal-footer button) {
    height: 56px;
  }
}

.mm-add-recipent {
  p {
    font-size: 14px;
    line-height: 20px;
    color: $text-black;
    margin-bottom: 24px;
  }

  .mm-input {
    padding-bottom: 12px;
  }

  .btn {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;

    &-disabled {
      pointer-events: none;
      background: $light-gray;
      color: $text-disabled;
    }
  }

  &--work-phone {
    display: flex;

    .mm-input {
      width: 100%;

      &:first-child {
        padding-right: 8px;
      }
    }
  }

  :deep(.vs__no-options) {
    color: $light-green;
    font-size: 14px;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding-left: 20px;

    em {
      opacity: 1 !important;
      font-style: normal;
    }
  }

  .mm-address-select {
    :deep(.v-select) {
      .vs__actions .mm-select__deselect {
        height: 16px;
        width: 16px;

        path {
          fill: rgb(33, 66, 55);
        }
      }
    }
  }
}
</style>
