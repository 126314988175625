export class TelephoneUtil {
  public static onKeydownPhone(
    currentValue: string,
    keydownEvent: KeyboardEvent,
    setValueFn: (value: string) => void,
  ): void {
    if (currentValue || keydownEvent.key !== '8') {
        return;
    }
    event?.preventDefault();
    setValueFn('7');
  }
}